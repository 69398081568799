import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'speiseplanTagContent',
  templateUrl: './speiseplanTagContent.component.html',
  styleUrls: ['./speiseplanTagContent.component.css'],
})
export class SpeiseplanTagContentComponent implements OnInit {

  @Input() mandant
  @Input() kunde
  @Input() speiseplan
  @Input() config
  @Input() tag
  @Input() darstellung
  @Input() modus

  public os
  public verpackungsArten
  public verpackungsId
  public speiseplanId


  constructor() {
    this.os = environment.type == 'web' ? 'web' : 'app'
  }

  ngOnInit() {
    if ( this.mandant.sonderKonfiguration.Verpackungsarten ) {
      this.verpackungsArten = this.mandant.sonderKonfiguration.Verpackungsarten
      this.verpackungsId = Object.keys(this.verpackungsArten)[0]
    }
    this.speiseplanId = (this.speiseplan.menutexte[Object.keys(this.speiseplan.menutexte)[0]].splanId).toString()

  }

  trennung(){
    let laenge = Object.keys(this.tag.value.tagesMenues).length
    let number = laenge <= 5 ? 5 : laenge <= 6 ? 6 : laenge <= 10 ? 5 : laenge <= 12 ? 6 : 6
    return number
  }

  protected readonly window = window;
}
