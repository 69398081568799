import {Component, Input, OnInit, AfterViewChecked, ViewChild} from '@angular/core';
import {SpeiseplanMenuService} from '../../services/speiseplanMenu.service';
import {environment} from '../../../../environments/environment';
import {StandartDialogComponent} from '../../../obs.shared/dialog/standart-dialog/standart-dialog.components';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SpeiseplanDetailsComponent} from '../speiseplanDetails/speiseplanDetails.component';
import {SpeiseplanEinzelbestellerService} from '../../services/speiseplan-einzelbesteller.service';

@Component({
  selector: 'speiseplanMenuVerpackung',
  templateUrl: './speiseplanMenuVerpackung.component.html',
  styleUrls: ['./speiseplanMenuVerpackung.component.css'],
})
export class SpeiseplanMenuVerpackungComponent implements OnInit, AfterViewChecked {

  @Input('menu') menu
  @Input('config') config
  @Input('bestellungen') bestellungen
  @Input('bestellung') bestellung
  @Input('mandant') mandant
  @Input('gesperrt') gesperrt
  @Input('kunde') kunde
  @Input('tag') tag
  @Input('speiseplan') speiseplan
  @Input('speiseplanMenuDatum') speiseplanMenuDatum

  public aktiv
  public datum
  public plattform
  public verpackungsArten


  constructor(
    public speiseplanMenuService: SpeiseplanMenuService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.plattform = environment.type
    this.verpackungsArten = Object.values(this.mandant.sonderKonfiguration.Verpackungsarten)
    if (this.kunde) {
      this.menu = this.speiseplanMenuService.speiseplanMenuBeschreibungTranslate(this.speiseplan, this.menu)
      this.bestellungen = this.speiseplan.bestellung.tage[this.speiseplanMenuDatum]
      this.bestellung = Object.values(this.bestellungen.bestellMenues).filter((element: any) => {
        if (element.menueTextId == this.menu.menueTextId) {
          return element
        }
      })[0]
      this.gesperrt = this.speiseplanMenuService.speiseplanMenuGesperrt(
        this.mandant,
        this.speiseplanMenuDatum,
        this.bestellung,
        this.config,
        this.speiseplan
      )
    } else {
      this.bestellungen = {}
      this.config = {
        bestellungen: {}
      }
      this.config.bestellungen[this.menu.menueTextId] = {markierung: false}
      this.bestellung = {
        mengeNeu: 0,
        mengeAlt: 0
      }
    }

    this.verifyMenu(this.bestellung)
  }

  ngAfterViewChecked() {
  }

  public menuKarteAnzeigen(menu, verpackungsArten){
    for (let verpackungsArtListe of verpackungsArten) {
      for (let verpackungsArt of verpackungsArtListe) {
        if (verpackungsArt['Einweg'] == menu.menueId){
          return false
        }
      }
    }
    return true
  }

  public speiseplanGetEinweg(bestellung, verpackungsArten) {
    for (let verpackungsArt of verpackungsArten) {
      for (let index of verpackungsArt) {
        if (index.Mehrweg == bestellung.menueId) {
          return index.Einweg
        }
      }
    }
    return false
  }

  public verpackungsTyp(text) {
    if (environment.type == 'mobil') {
      this.dialog.open(StandartDialogComponent, {
        data: {
          title: 'Verpackungsart',
          text: text
        }
      })
    }
  }

  open_details() {
    if (environment.type !== 'web') {
      let dialogRef = this.dialog.open(SpeiseplanDetailsComponent, {width: '90%'});
      dialogRef.componentInstance.menu = this.menu
      dialogRef.componentInstance.mandant = this.mandant
    } else {
      this.speiseplanMenuService.seitenleistenDetailsAktualisieren(this.mandant, this.menu)
    }
  }

  verifyMenu(menu) {
    for (let verpackungsArt of this.verpackungsArten) {
      for (let index of verpackungsArt) {
        if (index.Mehrweg == menu.menueId || index.Einweg == menu.menueId) {
          let correspondingMenuId = (index.Mehrweg == menu.menueId) ? index.Einweg : index.Mehrweg;
          let correspondingBestellung = this.bestellungen.bestellMenues[correspondingMenuId];
          setTimeout(() => {
            let element = document.getElementById(`speiseplanMenu ${menu.menueTextId}`);
            if (element) {
              if (correspondingBestellung.mengeNeu == correspondingBestellung.mengeAlt && correspondingBestellung.mengeAlt > 0) {
                element.style.backgroundColor = '#CCE5C5';
              } else if (correspondingBestellung.mengeNeu > correspondingBestellung.mengeAlt) {
                element.style.backgroundColor = '#ffdc88';
              } else if (correspondingBestellung.mengeAlt > correspondingBestellung.mengeNeu) {
                element.style.backgroundColor = '#f7bfc4';
              }
              if (this.config && this.config.bestellungen[menu.menueTextId].markierung) {
                element.style.backgroundColor = 'rgba(226, 233, 242, 1)';  // Marked menu
              }
            }
          }, 0);

          return;
        }
      }
    }
  }


  onBestellungChanged(newBestellung) {
    for (let verpackungsArt of this.verpackungsArten) {
      for (let index of verpackungsArt) {
        if (index.Mehrweg == this.bestellung.menueId) {
          this.bestellung = this.bestellungen.bestellMenues[index.Mehrweg];
          setTimeout(() => {
            let element = document.getElementById(`speiseplanMenu ${this.bestellung.menueTextId}`);
            if (element) {
              element.classList.remove('normal', 'positiv', 'negativ', 'markiert', 'aktiv');
              if (newBestellung.mengeNeu == newBestellung.mengeAlt && this.bestellung.mengeNeu == this.bestellung.mengeAlt) {
                element.style.backgroundColor = '#CCE5C5';
              } else if (newBestellung.mengeNeu != newBestellung.mengeAlt || this.bestellung.mengeNeu != this.bestellung.mengeAlt) {
                element.style.backgroundColor = '#ffdc88';
              } else if (newBestellung.mengeAlt > newBestellung.mengeNeu && this.bestellung.mengeAlt > this.bestellung.mengeNeu) {
                element.style.backgroundColor = '#f7bfc4';
              }
              if (this.config && this.config.bestellungen[this.menu.menueTextId].markierung) {
                element.style.backgroundColor = 'rgba(226, 233, 242, 1)';
              }
            }
          }, 0);
        }
      }
    }
  }


}
