<ng-container *ngIf="menuKarteAnzeigen(bestellung, verpackungsArten)">
  <div
      id="speiseplanMenu {{ menu.menueTextId }}"
      class="speiseplanMenu"
      *ngIf="config && menu"
      [class.normal]="bestellung.mengeNeu == bestellung.mengeAlt && bestellung.mengeAlt > 0"
      [class.positiv]="bestellung.mengeNeu > bestellung.mengeAlt"
      [class.negativ]="bestellung.mengeAlt > bestellung.mengeNeu"
      [class.markiert]="config ? config.bestellungen[menu.menueTextId].markierung : false"
      [class.aktiv]="this.kunde && plattform == 'web' ? menu.menueTextId == aktiv : menu.menueId == aktiv && speiseplanMenuDatum == datum"
  >
    <speiseplanMenuBeschreibung
        [menu]="menu"
        [mandant]="mandant"
        (click)="open_details()"
    ></speiseplanMenuBeschreibung>
    <ng-container>
      <div>
        <div>
          <div class="verpackungsArtLabel">
            <strong>Mehrweg</strong>
            <strong>{{ bestellung.einzelPreis | speiseplanpreis }}</strong>
          </div>
          <speiseplanMenuBestellung
              [menu]="menu"
              [mandant]="mandant"
              [bestellungen]="bestellungen"
              [bestellung]="bestellung"
              [config]="config.bestellungen[menu.menueTextId]"
              [gesperrt]="gesperrt"
              [label]="'Mehrweg'"
              *ngIf="kunde && bestellungen && !speiseplanMenuService.speiseplanNurBestellteMenues(mandant, menu)"
          >
          </speiseplanMenuBestellung>
        </div>
        <div *ngIf="speiseplanGetEinweg(bestellung, verpackungsArten)" class="verpackungsArtMenu">
          <div class="verpackungsArtLabel">
            <strong>Einweg</strong>
            <strong>{{ (bestellung.menueId| speiseplanMenuSelector: verpackungsArten| speiseplanGetTagesmenu: this.bestellungen: 'bestellung').einzelPreis | speiseplanpreis }}</strong>
          </div>
          <speiseplanMenuBestellung
              [mehrwegMenu]="menu"
              [menu]="bestellung.menueId| speiseplanMenuSelector: verpackungsArten| speiseplanGetTagesmenu: this.tag: 'menu'"
              [mandant]="mandant"
              [bestellungen]="bestellungen"
              [bestellung]="bestellung.menueId| speiseplanMenuSelector: verpackungsArten| speiseplanGetTagesmenu: this.bestellungen: 'bestellung'"
              [config]="config.bestellungen[menu.menueTextId]"
              [gesperrt]="gesperrt"
              [label]="'Einweg'"
              (bestellungChanged)="onBestellungChanged($event)"
              *ngIf="kunde && bestellungen && !speiseplanMenuService.speiseplanNurBestellteMenues(mandant, menu)"
          >
          </speiseplanMenuBestellung>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="kunde">
      <speiseplanMenuTelefon [bestellung]="bestellung" *ngIf="speiseplanMenuService.speiseplanNurBestellteMenues(mandant, menu)"></speiseplanMenuTelefon>
    </ng-container>
  </div>
</ng-container>
